// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
// @import 'modules/menu';
@import 'modules/menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';





/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-image-text {
    div.container-fluid.row {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: stretch;
        justify-content: center;
        & > div {
            min-height: 450px;
            transition: .3s;
            width: 50%;
        }
        @media screen and (max-width: $size-xs-max){
            flex-direction: column;
            transition: .3s;
            & > div {
                transition: .3s;
                width: 100%;
                min-height: 400px;
            }
        }
    }


    div.text-side {
        padding: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-black;
        h3 {
            font-weight: bold;
            font-size: $font-size-38;
            color: $color-white;
            text-transform: initial;
            padding-bottom: 20px;
            span {
                font-weight: 900;
                text-transform: uppercase;
            }
        }
        p {
            font-weight: normal;
            font-size: $font-size-24;
            color: $color-white;
            margin: 0;
            line-height: 1.5;
        }
    }
}

#section-parallax {
    background: url(../images/accueil_section02_bg.jpg) no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    border-top: 10px solid #f50000;
    border-bottom: 10px solid #f50000;
    min-height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5%;
    h4 {
        font-weight: bold;
        font-size: $font-size-38;
        color: $color-black;
        padding-bottom: 35px;
    }
    .section-list {
        display: flex;
        ul {
            list-style-image: url(../images/accueil_section02_crochet.png);
            padding-left: 50px;
            li {
                font-weight: bold;
                font-size: $font-size-26;
                color: $color-black;
                line-height: 1.5;
                padding-bottom: 5px;
                padding-left: 20px;
            }
        }
        .left-side {
            width: 50%;
        }
    }
}

#section-text {
    padding: 75px 5%;
    .text-box {
        width: 85%;
        p {
            font-weight: normal;
            font-size: $font-size-24;
            color: $color-black;
            margin: 0;
            line-height: 1.5;
        }
    }
}

#section-bursts {
    display: flex;
    padding-bottom: 5px;
    a.burst {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        outline: 3px solid #f50000;
        transition: 0.3s;
        .text {
            margin-right: 5%;
            padding-left: 11%;
            h4 {
                font-weight: bold;
                font-size: $font-size-32;
                color: $color-black;
                transition: 0.3s;
            }
            h3 {
                font-weight: 900;
                font-size: $font-size-38;
                color: $color-black;
                transition: 0.3s;
            }
        }
        &:first-child {
            margin-right: 20px;
        }
        &:hover {
            outline: 6px solid #000000;
            transition: 0.3s;
            .text {
                h3, h4 {
                    color: #f50000;
                    transition: 0.3s;
                }
            }
        }
    }
}


section.section-full-black {
    background-color: $color-black;
    padding: 70px 4%;
    h3 {
        color: $color-white;
        font-size: $font-size-40;
        font-weight: 900;
    }
    h4 {
        color: $color-white;
        font-size: $font-size-32;
    }
    p {
        color: $color-white;
        font-size: $font-size-22;
        font-weight: 400;
        line-height: 1.5;
    }
}

section.section-image-text {
  background-color: $color-white;
  div.container-fluid.row {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
          min-height: 450px;
          transition: .3s;
          width: 50%;
      }
      @media screen and (max-width: $size-xs-max){
          flex-direction: column;
          transition: .3s;
          & > div {
              transition: .3s;
              width: 100%;
              min-height: 400px;
          }
      }
  }


  div.text-side {
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
        font-size: $font-size-38;
        color: $color-black;
        text-transform: initial;
        padding-bottom: 20px;
        font-weight: 900;
        span {
            text-transform: uppercase;
        }
    }
    li {
      list-style: square;
      line-height: 1.3;
      text-transform: inherit;
      font-weight: 600;
      font-size: $font-size-30;
    }
  }
}


section.section-parallax-pages {
    &.equilibrage {
       background-image: url('../images/equilibrage_section05_pho01.jpg');
    }
    padding: 70px 4%;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

    h3 {
        font-size: $font-size-30;
        font-weight: 900;
        color: $color-black;
    }
    ul {
        padding: 0 20px;
        list-style: none;
        li {
            font-size: $font-size-22;
            font-weight: 700;
            line-height: 1.3;
        }
    }
    @media screen and (max-width: $size-xs-max){
        &.equilibrage {
           background-image: url('../images/equilibrage_section05_pho01_m.jpg');
        }
    }
}


body.realisations {
    margin-top: 130px;
    transition: .3s;

    background-color: $color-black;
    @media screen and (max-width: 1300px){
      margin-top: $size-nav-height-mobile;
      transition: .3s;
    }

    section.gallery {
      padding: 30px 0;
        div.container-fluid.row {
            width: 83%;
            max-width: 1700px;
            margin: auto;
        }
        h3.title-page {
            color: $color-white;
            font-weight: 900;
            font-size: $font-size-50;
        }

        div.gallery-wrapper {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;

            @media screen and (max-width: $size-lg-min){
              grid-template-columns: repeat(1fr, 2fr);
            }
            display: grid;
            grid-gap: 2rem;

            & > div.item {
                border: 5px solid $color-primary;
                padding: 1rem;
                //margin-bottom: 30px;
                transition: .3s;
                background-color: $color-white;
                img {
                    opacity: 1;
                    transition: .3s;
                }
                .title h3 {
                    color: $color-black;
                    font-weight: 900;
                    margin-top: 15px;
                    font-size: $font-size-30;
                }
                .description {
                    p, li {
                      color: $color-black;
                      font-weight: 400;
                      line-height: 1.3;
                    }
                }
                a {
                    cursor: pointer;
                    &:hover {
                        img {
                            opacity: .6;
                            transition: .3s;
                        }
                    }
                }
            }
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);
                & > div.item {
                    width: auto;
                    transition: .3s;
                }
            }
        }
    }
}

div.section-form {
    background-color: $color-black;
    padding: 50px 0;
}

section.coordonnees {
  padding: 30px 0;
    div.container-fluid.row {
        width: 83%;
        max-width: 1700px;
        margin: auto;
    }
    h3.title-page {
        color: $color-white;
        font-weight: 900;
        font-size: $font-size-50;
    }

    div.text-map-box {
      background-color: $color-white;
      border: 3px solid $color-primary;
      //padding: 2rem 0;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto;

        @media screen and (max-width: $size-lg-min){
          grid-template-columns: repeat(2, 1fr);
          @media screen and (max-width: $size-xs-max){
            grid-template-columns: 1fr;
          }
        }
        display: grid;
        grid-gap: 40px;
    }

    div.text-coordonnees {
      background-image: url('../images/coordonees_logo.jpg');
      background-size: 190px;
      background-position: bottom right;
      background-repeat: no-repeat;
      @media screen and (max-width: $size-slider-breakpoint){
        background-image: none;
      }
        padding: 30px 20px;
        .title {
            width: fit-content;
            h3 {
              font-size: $font-size-30;
              color: $color-black;
              font-weight: 900;
            }
            div.separator {
                height: 6px;
                width: 100%;
                border-radius: 3px;
                background-color: $color-primary;
                border: 2px solid $color-black;
            }
        }
        p {
            font-size: $font-size-22;
            font-weight: 400;
            line-height: 1.2;
            span {
                font-weight: 700;
            }
        }
        .phone_text a {
            color: $color-primary;
            transition: .3s;
            line-height: 1.3;
            font-size: $font-size-28;

            &:hover, &:focus {
                color: $color-black;
                transition: .3s;
            }
        }
        .email_text a {
            color: $color-black;
            transition: .3s;
            line-height: 1.3;
            font-size: $font-size-28;

            &:hover, &:focus {
                color: $color-primary;
                transition: .3s;
            }
        }
    }
}
/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1715px) {

    #section-bursts a.burst .text h3 {
        font-size: 1.7rem;
    }
    .slideshow-wrap .caption {
        width: 625px;
    }
}

@media screen and (max-width: 1630px) {
    #section-bursts a.burst .text h4 {
        font-size: 1.4rem;
    }
    #section-bursts a.burst .text h3 {
        font-size: 1.5rem;
    }
    #section-parallax .section-list ul li {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1550px) {

#section-bursts a.burst .text {
    padding-left: 5%;
}
}

@media screen and (max-width: 1480px) {

nav.module-menu .menu-navbar .wrapper.left .main-menu > ul li div {
    font-size: 0.9rem;
}
nav.module-menu .menu-navbar .wrapper.left .main-menu > ul {
    padding: 0 10px;
}
nav.module-menu .menu-navbar .wrapper.center .logo {
    max-width: 250px;
}
.slideshow-wrap .caption {
    width: 550px;
}
#section-bursts a.burst .text h3 {
    font-size: 1.3rem;
}
#section-bursts a.burst .text h4 {
    font-size: 1.3rem;
}
}

@media screen and (max-width: $size-md-max) {

nav.module-menu .menu-navbar .wrapper.left, nav.module-menu .menu-navbar .wrapper.right a.link {
    display: none;
}
nav.module-menu .menu-navbar {
    height: 105px;
}
.slideshow-wrap .caption {
    width: 450px;
}
#section-bursts a.burst .icon {
    width: 50%;
}
}

@media screen and (max-width: 1024px) {

nav.module-menu .menu-navbar .wrapper.center {
    padding-left: 1.5rem;
}
.slideshow-wrap .caption {
    width: 300px;
}
.slideshow-wrap .caption h3 {
    font-size: 1.8rem;
}
.slideshow-wrap .caption .text {
    bottom: 18%;
    left: 10%;
}
#section-image-text div.text-side h3 {
    font-size: 1.8rem;
}
#section-parallax {
    padding: 0 5%;
}
#section-parallax .section-list .left-side {
    width: 70%;
}
}

@media screen and (max-width: $size-sm-max) {

.slideshow-wrap .caption {
    width: 220px;
}
#section-bursts a.burst .text h4 {
    font-size: 1.1rem;
}
#section-bursts a.burst .text h3 {
    font-size: 1.1rem;
}
#section-image-text div.text-side h3 {
    font-size: 1.6rem;
}
#section-image-text div.container-fluid.row {
    flex-direction: column;
}
#section-image-text div.container-fluid.row .text-side {
    min-height: 400px;
    padding: 0;
}
#section-image-text div.container-fluid.row .text-side .text-box {
    width: 100%;
    padding: 5%;
}
#section-image-text div.container-fluid.row > div {
    min-height: 550px;
    width: 100%;
}
}

@media screen and (max-width: $size-xs-max) {

#section-parallax {
  background-image: url('../images/accueil_section02_bg_m.jpg');
}
nav.module-menu .menu-navbar .wrapper.center .logo {
    max-width: 215px;
}
nav.module-menu .menu-navbar {
    height: 90px;
}
nav.module-menu .menu-navbar .wrapper.center {
    padding-left: 1rem;
}
.slideshow-wrap .caption {
    width: 35px;
}
#section-image-text div.text-side h3 {
    font-size: 1.9rem;
}
#section-parallax {
    padding: 50px 5%;
}
#section-parallax .section-list .left-side {
    width: 100%;
}
#section-image-text div.container-fluid.row .text-side {
    min-height: 450px;
}
#section-bursts {
    flex-direction: column;
    align-items: center;
}
#section-bursts a.burst:first-child {
    margin-right: 0;
    margin-bottom: 20px;
}
#section-bursts a.burst {
    width: 98%;
}
#section-bursts a.burst .text h4, #section-bursts a.burst .text h3 {
    font-size: 1.2rem;
}
#section-bursts a.burst .icon {
    width: 85%;
}
#section-bursts a.burst .text {
    width: 65%;
}
#section-image-text div.container-fluid.row > div {
    min-height: 315px;
}
#section-text .text-box {
    width: 100%;
}
#section-parallax .section-list {
    flex-direction: column;
}
}
