/*****************************************************/
// MENU
/* needs main.js to toggle var menu = ".menu-mobile"; */

nav.module-menu {
    position: relative;

    ul {
        list-style-type: none;
    }

    /****************************************************
    /* Set if the menu is over the slide show or not
    */
    // if menu do NOT overlap the content
    $menu-bottom-padding-desktop : $size-nav-height;
    $menu-bottom-padding-mobile : $size-nav-height-mobile;
    // if menu DO overlap over the content
    $menu-bottom-padding-desktop : 0;
    $menu-bottom-padding-mobile : 0;
    // sets the padding according to context
    padding-bottom: $menu-bottom-padding-desktop;
    @media screen and (max-width: $size-nav-toggle-breakpoint) {
        padding-bottom: $menu-bottom-padding-mobile;
    }

    .menu-navbar {
        @extend .flex;
        position: fixed;
        top: 0;
        z-index: $z-index-menu;
        width: 100%;
        height: 125px;
        border-bottom: 10px solid #f50000;
        transition: all $transition-speed-fast ease;

        @extend .bg-color-nav; // see _colors.scss

        /* purgecss start ignore */
        &.nav-invisible {
            top: -135px;
            transition: all $transition-speed-fast ease;
        }
        &.nav-visible {
            top: 0;
            transition: all $transition-speed-fast ease;
        }

        @media screen and (max-width: $size-nav-shift-breakpoint) {
            //height: 85px;
            transition: all $transition-speed-fast ease;
            &.nav-invisible {
                //top: -$size-nav-height-mobile;
                transition: all $transition-speed-fast ease;
            }
            &.nav-visible {
                top: 0;
                transition: all $transition-speed-fast ease;
            }
        }
        /* purgecss end ignore */

        .wrapper {
            &.center {
                //background: $bg-color-nav;
                @extend .flex;
                .logo {
                    height: 100%;
                    width: auto;
                    @extend .flex;
                    @extend .justify-center;
                    @extend .items-center;
                    a {
                        @extend .text-color-nav;
                        @extend .font-nav-logo;
                        &:hover {
                            @extend .text-color-nav-hover;
                        }
                    }
                    @media screen and (max-width: $size-nav-shift-breakpoint){
                        max-width: 275px;
                    }
                }
            }
            &.left {
                .main-menu-top, .main-menu {
                    align-self: flex-end;
                }
                .main-menu-top {
                    li.icon {
                        padding: 0 20px;
                    }
                }
                a.link {
                    position: absolute;
                    left: 0;
                    background: #000000;
                    height: 115px;
                    width: 150px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    img {
                        &:nth-child(1) {
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            position: absolute;
                            opacity: 0;
                        }
                    }
                    &:hover {
                        img {
                            &:nth-child(1) {
                                opacity: 0;
                            }
                            &:nth-child(2) {
                                opacity: 1;
                                top: 28px;
                            }
                        }
                        div.phonenumber {
                            color: #f50000;
                        }
                    }
                    .phonenumber {
                        font-weight: bold;
                        font-size: $font-size-18;
                        color: $color-white;
                    }
                }
            }
            &.right {
                .main-menu-top, .main-menu {
                    align-self: flex-start;
                }
                .main-menu-top {
                    li.icon {
                        padding: 0 20px;
                    }
                }
                a.link {
                    position: absolute;
                    right: 0;
                    background: #000000;
                    height: 115px;
                    width: 150px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        &:nth-child(1) {
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            position: absolute;
                            opacity: 0;
                        }
                    }
                    &:hover {
                        img {
                            &:nth-child(1) {
                                opacity: 0;
                            }
                            &:nth-child(2) {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            &.right, &.left {
                flex: auto;
                @extend .flex;
                @extend .flex-column;
                @extend .justify-center;
                @extend .items-center;
                .main-menu-top, .main-menu {
                    height: 35%;
                    @media screen and (max-width: $size-nav-toggle-breakpoint) {
                        display: none;
                    }
                    & > ul {
                        @extend .flex;
                        @extend .flex-wrap;
                        // @extend .justify-end; // pour menu a droite
                        @extend .justify-center; // pour menu au centre de l'espace
                        // @extend .justify-start; // pour menu a gauche
                        @extend .items-center; // pour menu centré verticalement
                        // @extend .items-start; // pour menu en haut
                        @extend .items-end; // pour menu en bas
                        padding: 0 30px;
                        @extend .m0;
                        height: 100%;
                        li {
                            height: 100%;
                            transition: all linear 0.1s;
                            @extend .flex;
                            position: relative;
                            @extend .items-center;
                            // @extend .items-start; // pour menu en haut
                            // @extend .items-end; // pour menu en bas
                            //@extend .pr4;
                            & > a, & > span.menu-title {padding: 0 20px;}
                            &.icon {
                              //padding-right: 40px;
                              line-height: 1;
                              div {
                                font-weight: bold;
                                font-size: $font-size-20;
                                color: #1a3d63;
                                &:hover {
                                     color: #ffd51c
                                }
                              }
                              a {
                                  padding: 0;
                              }
                              a .hoverable {
                                  @extend .filter-normal;
                              }
                              a:hover .hoverable {
                                  @extend .filter-hover;
                              }
                            }
                            &.phone {
                                a {
                                    padding-right: 0;
                                    margin-bottom: 5px;
                                    div.phonenumber {
                                        font-weight: 600;
                                        font-size: $font-size-24;
                                    }
                                }
                            }
                            &.phone, &.lang {
                                & > a, & > span.menu-title {
                                    @extend .font-nav-phone;
                                    @extend .text-color-nav-phone;
                                    padding-left: 0;
                                }
                                &:hover > a, &:hover > span.menu-title {
                                    @extend .text-color-nav-phone-hover;
                                }
                                a .hoverable {
                                    @extend .filter-normal;
                                }
                                a:hover .hoverable {
                                    @extend .filter-hover;
                                }
                            }
                            &.lang {
                                //@extend .pr8;
                                & > a, & > span.menu-title {
                                    // color: $color-black;
                                    //padding: 0 20px;
                                }
                            }
                        }
                    }
                }

                .main-menu {
                    & > ul {
                        li {
                            &:first-child a {
                                &::before {
                                    display: none;
                                }
                            }
                            & > a, & > span.menu-title {
                                //@extend .px4;
                                @extend .text-color-nav;
                                @extend .font-nav-items;
                                position: relative;
                                transition: all linear 0.1s;
                                &::before {
                                    content: url(../images/accueil_menu_point_rouge.jpg);
                                    height: 10px;
                                    width: 10px;
                                    position: absolute;
                                    left: 0;
                                }
                            }
                            div {
                                font-weight: bold;
                                font-size: $font-size-20;
                                color: $color-black;
                                &:hover {
                                    color: #f50000;
                                }
                            }
                            &:hover {
                                & > a, & > span.menu-title {
                                    @extend .text-color-nav-hover;
                                }
                            }
                            &.active, &:active {
                                div, span.menu-title {
                                    @extend .text-color-nav-active;
                                }
                                div {
                                    color: #f50000;
                                }
                            }
                            &.dropdown:hover + .submenu,
                            &.dropdown:hover > .submenu {
                                opacity: 100;
                                visibility: visible;
                            }

                            .submenu {
                                opacity: 0;
                                visibility: hidden;
                                // @extend .flex;
                                // @extend .justify-end;
                                position: absolute;
                                top: 100%;
                                left: 50%;
                                transform: translateX(-50%);
                                width: max-content;
                                //background: $bg-color-submenu;
                                // @media screen and (max-width: $size-nav-shift-breakpoint) {
                                //     top: $size-nav-height-mobile;
                                // }
                                // left: 0;
                                // width: 100%;
                                padding: 10px 0;
                                //@extend .bg-color-nav-toogle-back;
                                // background-color: rgba(#fff, 0.9);
                                transition: opacity 0.1s ease-in;
                                border-bottom: 2px solid black;
                                // border-top: 1px solid #999;
                                ul {
                                    @extend .flex;
                                    @extend .flex-column;
                                    @extend .justify-start;
                                    flex: 1 1 auto;
                                    // @extend .m0;
                                    @extend .p0;
                                    li.subitem {
                                        width: 100%;

                                        height: $size-nav-subitem;
                                        &:hover {
                                            //background: $bg-color-submenu-active;
                                            & > a, & > span.menu-title {
                                              color: $color-white;
                                            }

                                        }
                                        &:active {
                                            @extend .bg-color-nav-active;
                                            //background: $bg-color-submenu-active;
                                            div, span.menu-title {
                                                color: $color-white;
                                            }
                                        }
                                        & > a {
                                            font-weight: 300;
                                            @extend .font-nav-drop-items;
                                            margin: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .main-menu-top {
                    //border-bottom: 1px solid $color-grey;
                }
            }
        }
    }
    &.scrolled {
        .menu-navbar {
            // position: fixed;
        }
    }
    .empty-line, .white-line {
        bottom: -4px;
        height: 3px;
        width: 100%;
        background-color: transparent;
        position: absolute;
    }

    .white-line {
        bottom: -10px;
        background-color: $color-white;
    }
    .menu-toggle {
        @media screen and (min-width: $size-nav-toggle-breakpoint) {
            display: none;
        }
        align-self: flex-end;
        cursor: pointer;
        padding: 0 15px;
        .bar-top, .bar-bottom {
            // box-shadow: 0px 0px 4px $color-black;
            width: 100%;
            height: $size-nav-toggle-bar-height;
            display: block;
            @extend .bg-color-nav-toggle;
        }
        .bar-top {
            margin-bottom: 3px;
        }
        .bar-bottom {
            margin-top: 1px;
        }
        .word {
            // text-shadow: 0px 0px 6px white;
            @extend .font-1-bold;
            @extend .text-color-nav-toggle;
        }
    }
    /************ menu mobile ***************************/
    #menu-mobile {
        width: 100%;
        top: 0;
        right: -100%;
        z-index: 1200;
        // overflow: hidden;
        position: fixed;
        background-color: #fff;
      //  @extend .bg-color-nav-toogle-back;
        @include transition(all 0.3s ease-in);
        @media screen and (max-width: $size-nav-toggle-breakpoint) {
            &.toggled {
                overflow-y: auto;
                bottom: 0; // pour avoir le scroll
                @include transform(translate(-100%, 0%));
            }
        }
        .menu-toggle {
            float: right;
            margin: 15px 0 0 0;
        }
        ul {
            margin: 25px auto;
            padding: 30px;
            li {
                // position: relative;
                text-align: center;
                line-height: 100%;
                padding: 15px;
                border-bottom: 1px solid #f50000;
                &:nth-last-child(1) {
                    border-bottom: none;
                }
                &.top-bar {
                    display: flex;
                    @extend .flex-wrap;
                    @extend .justify-between;
                    @extend .items-center;
                    div.icons {
                        flex: 1 1;
                        & > a {
                            img {
                                margin: 5px;
                            }
                        }
                    }
                    a {
                        flex: 1 1;
                    }
                }
                & > a, & > span.menu-title {
                    @extend .font-nav-items;
                    @extend .text-color-nav;
                }
                .submenu {
                    margin: 0px auto;
                    padding: 10px 0 10px;
                    ul {
                        margin: 0;
                        padding: 0;
                    }
                    li {
                        border-bottom: none;
                        padding-top: 10px;
                        a, a div {
                            @extend .font-nav-drop-items;
                            @extend .text-color-nav;
                            font-weight: 300;
                        }
                        &:hover {
                            > a, > a div {
                                @extend .text-color-nav-hover;
                            }
                        }
                        &.active {
                            a, a div {
                                @extend .text-color-nav-active;
                            }
                        }
                    }
                }
                &:hover {
                    > a, > a div { @extend .text-color-nav-hover; }
                }
                &.active, &.phone, &.lang {
                    > a, > a div { @extend .text-color-nav-active; }
                }
            }
        }
    }
}
