.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 0;
        right: 0;
        left: 50%;
        bottom: 0;
        //width: 50%;
        transition: .3s;
        background: rgba(0, 0, 0, 0.5);
        //transform: translateY(-50%);
        padding: 10px 2rem;
        &.left-shadow {
          right: 50%;
          left: 0;
        }
        &--title {
            width: 100%;
            max-width: 650px;
            font-size: $font-size-50;
            font-weight: 700;
            text-transform: inherit;
            color: $color-text-contrast;
            text-shadow: 0 0 10px $color-black;
            span {
              font-weight: 900;
              text-transform: uppercase;
            }
        }
        &--subtitle {
            width: 100%;
            text-align: center;
            font-size: 3vw;
            font-weight: 300;
            color: $color-1;
            text-shadow: 0 0 10px $color-black;
        }

    }
}
@media screen and (max-width: $size-md-max) {
    .module-header {
        &--caption {
            &--title {
                //font-size: 6vw;
            }
            &--subtitle {
                //font-size: 4vw;
            }
        }
    }
}
@media screen and (max-width: $size-sm-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 25px;
            }
            &--subtitle {
                //font-size: 5vw;
            }
        }
    }
}
@media screen and (max-width: $size-xs-max) {
    .module-header {
        &--caption {
            width: 100%;
            left: 0;
            right: 0;
            transition: .3s;
            padding: 0;
            align-items: flex-end;
            &--title {
                //font-size: 8vw;
                padding: 10px;
            }
            &--subtitle {
                font-size: 6vw;
            }
        }
    }
}
